import React from "react"
import Layout from "../components/layout"

const Samband = () => {
    return (        
        <Layout>
            <h1>Samband</h1>            
        </Layout>        
    )
}

export default Samband
